import React, { useContext, createContext, useState } from 'react';
import UserContext from './Context';
import settingsLogo from '../images/options.png';
import settingsLogoWhite from '../images/options-white.png';
import './MenuPanel.css';

import aquarium from '../images/shutterstuck-sea.jpeg';

const MenuContext = createContext();
export default function MenuPanel(){
	const [openedOptions, setOpenedOptions] = useState(0);
	// const [menuVisible, setMenuVisible] = useContext(UserContext).sessionTime;
	const [menuVisible, setMenuVisible] = useContext(UserContext).menuVisible;
	const [isActive, setIsActive] = useContext(UserContext).isActive;
	const language = useContext(UserContext).language[0];
	function ToggleMenuVisible(){
    	
    	if(!menuVisible){
    		setIsActive(false);
    	}
    	setMenuVisible(!menuVisible);
  	}
	let content = {
		EN: {
			breathing_btn_text: 'Breathing time',
			background_btn_text: 'Background',
			language_btn_text: 'Language',
			learn_more_btn_text: 'Learn more',
		},
		FR: {
			breathing_btn_text: 'Temps de respiration',
			background_btn_text: 'Fond',
			language_btn_text: 'Langage',
			learn_more_btn_text: 'En savoir +',
		}
	};
	content = content[language];
	return(
		<MenuContext.Provider value={{ 
	      isActive: [isActive, setIsActive], 
	      openedOptions: [openedOptions, setOpenedOptions],
	    }}>
			<div className='menu-panel-section'>
				<div className='menu-opener menu-opener--out-menu'><div className='settings-logo-container'>
					<img src={settingsLogo} className='settings-logo' alt='settings-logo' onClick={ToggleMenuVisible}/>
				</div></div>
				<div className={`menu-panel menu-panel--${menuVisible ? 'active' : 'inactive'}`}>
					<div className='menu-opener menu-opener--in-menu'><div className='settings-logo-container'>
					<img src={settingsLogoWhite} className='settings-logo' alt='settings-logo' onClick={ToggleMenuVisible}/>
					</div></div>
					<MenuPanelButton text={content['breathing_btn_text']} id='1' optionsComponent={<BreathingOptions/>}/>
					<MenuPanelButton text={content['background_btn_text']} id='2' optionsComponent={<BackgroundOptions/>}/>
					<MenuPanelButton text={content['language_btn_text']} id='10' optionsComponent={<LanguageOptions/>}/>
					<MenuPanelButton text={content['learn_more_btn_text']} id='3' optionsComponent={<LearnMoreMenu/>}/>
				</div>
			</div>
		</MenuContext.Provider>
	);
}



function MenuPanelButton(props){
	const context = useContext(MenuContext);
	const [openedOptions, setOpenedOptions] = context.openedOptions;
	function toggleOpenedOptions(){
		setOpenedOptions(props.id);
	}
	return(
		<div className='menu-panel-section-duo'>
			<div className='menu-panel-section-btn' onClick={toggleOpenedOptions}>{props.text}</div>
			<MenuPanelOptions id={props.id} optionsComponent={props.optionsComponent}/>
		</div>
	);
}

function MenuPanelOptions(props){
	const context = useContext(MenuContext);
	const [openedOptions, setOpenedOptions] = context.openedOptions;
	return(
		<div className={`menu-panel-section-reveal menu-panel-section-reveal--${(props.id == openedOptions)? 'active' : 'inactive'}`}>
			{props.optionsComponent}
		</div>
	);
}

function BreathingOptions(){
	const [sessionTime, setSessionTime] = useContext(UserContext).sessionTime;
	const [currentTime, setCurrentTime] = useContext(UserContext).currentTime;
	function SetTime(time){ setSessionTime(time); setCurrentTime(time); }
	
	const [upTime, setUpTime] = useContext(UserContext).upTime;
	const [holdTime, setHoldTime] = useContext(UserContext).holdTime;
	const [downTime, setDownTime] = useContext(UserContext).downTime;
	const [waitTime, setWaitTime] = useContext(UserContext).waitTime;
	const language = useContext(UserContext).language[0];
	let content = {
		EN: {
			session_title: 'Session Time (minutes)',
			up_title: 'Inhale time (seconds)',
			hold_title: 'Hold time after inhale (seconds)',
			down_title: 'Exhale time (seconds)',
			wait_title: 'Hold time between breathes (seconds)',
		},
		FR: {
			session_title: 'Temps de respiration (minutes)',
			up_title: 'Durée inspiration (secondes)',
			hold_title: 'Durée du retient de souffle (secondes)',
			down_title: 'Durée expiration (secondes)',
			wait_title: 'Durée entre les souffles (secondes)',
		}
	};
	content = content[language];

	return(
		<div className='timer-selector'>
			<Slider min='1' max='10' multiplier='60' step='1' callback={SetTime} title={content['session_title']} value={sessionTime/60}/>
			<Slider min='3' max='15' multiplier='1' step='0.5' callback={setUpTime} title={content['up_title']} value={upTime}/>
			<Slider min='0' max='10' multiplier='1' step='0.5' callback={setHoldTime} title={content['hold_title']} value={holdTime}/>
			<Slider min='3' max='15' multiplier='1' step='0.5' callback={setDownTime} title={content['down_title']} value={downTime}/>
			<Slider min='0' max='10' multiplier='1' step='0.5' callback={setWaitTime} title={content['wait_title']} value={waitTime}/>
		</div>
	);
}
function BackgroundOptions(){
	const [activeBackground, setActiveBackground] = useContext(UserContext).activeBackground;

	function handleClicky(background) {
		setActiveBackground(background);
	}
	return(
		<div className="background-selector">
			<div className="background-selector__single" onClick={() => handleClicky('water') }>
				<div className="background-selector__single-title">Ocean</div>
				<div className="background-selector__single-img" style={{backgroundImage: `url(${aquarium})`}}></div>
			</div>
			<div className="background-selector__single" onClick={() => handleClicky('plain')}>
				<div className="background-selector__single-title">Plain</div>
				<div className="background-selector__single-img" style={{backgroundColor: "#111"}}></div>
			</div>
		</div>
	);
}
function LanguageOptions(){
	const [language, setLanguage] = useContext(UserContext).language;
	function handleChange(event) {
		setLanguage(event.target.value);
	}
	return(
		<select name='languages' selected={language} onChange={handleChange}>
			<option value='EN'>English</option>
			<option value='FR'>Français</option>
		</select>
	);

}
function LearnMoreMenu(){
	const language = useContext(UserContext).language[0];
	let content = {
		EN: {
			text: 'The importance of breathing: it\'s important',
		},
		FR: {
			text: 'L\'importance de respirer: c\'est important',
		}
	};
	content = content[language];
	return(
		<div>
			{content['text']}
		</div>
	);
}
function Slider(props){
	function handleChange(event) {
		const newValue = event.target.value * props.multiplier;
		props.callback(newValue);
	}
	return(
		<div className='slide-container'>
			<div className='slide-title'>{props.title}</div>
			<input type='range' min={props.min} max={props.max} defaultValue={props.value} step={props.step} className='slider' onChange={handleChange} />
			<div className='slide-value'>{props.value}</div>
		</div>
	);
}