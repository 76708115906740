import React from 'react';
import fish1 from '../images/fish-1.png';
import fish2 from '../images/fish-2.png';
import fish3 from '../images/fish-3.png';
import fish4 from '../images/fish-4.png';
import fish5 from '../images/fish-5.png';
import fish6 from '../images/fish-6.png';
import fish7 from '../images/fish-7.png';
import fish8 from '../images/fish-8.png';
import fish9 from '../images/fish-9.png';
import crab from '../images/crab.png';
import fishsvg from '../images/fish.png';
import aquarium from '../images/shutterstuck-sea.jpeg';

import './BackgroundWater.css';

export default function BackgroundWater(){
	return(
		<div className='background-holder'>
			<div className='background' style={{ backgroundImage: `url(${aquarium})` }}>
			</div>
			<div className='fishes-container'>
				<BackgroundFishes delay='0' duration='36' direction='left' height='35'/>
				<BackgroundFishes delay='10' duration='34' direction='right' height='10'/>
				<Fish image={fish1} delay='0' duration='32' direction='right' height='20'/>
				<Fish image={fish2} delay='20' duration='36' direction='left' height='30'/>
				<Fish image={fish3} delay='13' duration='34' direction='right' height='25'/>
				<Fish image={fish4} delay='16' duration='32' direction='left' height='50'/>
				<Fish image={fish5} delay='23' duration='31' direction='right' height='10'/>
				<Fish image={fish6} delay='6' duration='40' direction='right' height='15'/>
				<Fish image={fish7} delay='25' duration='34' direction='left' height='25'/>
				<Fish image={fish8} delay='18' duration='37' direction='right' height='60'/>
				<Fish image={fish9} delay='9' duration='35' direction='left' height='40'/>
				<Fish image={crab} delay='8' duration='44' direction='right' height='80'/>
			</div>
		</div>
	);
}

/**
 * props: duration, delay, height, image, direction
 **/
function Fish(props){
	const fishClass = 'fish ' +((props.direction=='right') ? 'swim-right' : 'swim-left');

	const fishStyles = {
		// animationName: ((props.direction=='right') ? 'swim-right' : 'swim-left'),
		animationDuration: `${props.duration}s`,
		animationDelay: `${props.delay}s`,
		marginTop: `${props.height}vh`,
    };
    if(props.direction=='right') {
    	fishStyles['transform'] = 'translateX(-350px) scaleX(-1)';
    }
    else{
    	fishStyles['transform'] = 'translateX(100%)';
    }
	return(
		<div className={fishClass} style={fishStyles}>
			<img src={props.image} className='fish-image'  alt=''/>
		</div>
	);
}
//https://codepen.io/monchito/pen/RPEqvo
function BackgroundFishes(props){
	const fishClass = 'fish background-fish ' +((props.direction=='right') ? 'swim-right' : 'swim-left');
	const fishStyles = {
		// animationName: ((props.direction=='right') ? 'swim-right' : 'swim-left'),
		animationDuration: `${props.duration}s`,
		animationDelay: `${props.delay}s`,
		marginTop: `${props.height}vh`,
    };
    if(props.direction=='right') {
    	fishStyles['transform'] = 'translateX(-350px) scaleX(-1)';
    }
    else{
    	fishStyles['transform'] = 'translateX(100%)';
    }
	return(
		<div className={fishClass} style={fishStyles}>
			<div className='background-fish--holder'>
				<img src={fishsvg} className='background-fish-image'  alt=''/>
				<img src={fishsvg} className='background-fish-image'  alt=''/>
				<img src={fishsvg} className='background-fish-image'  alt=''/>
				<img src={fishsvg} className='background-fish-image'  alt=''/>
				<img src={fishsvg} className='background-fish-image'  alt=''/>
				<img src={fishsvg} className='background-fish-image'  alt=''/>
			</div>
		</div>
	);
}