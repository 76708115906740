
import { useState } from 'react';
import './App.css';
import BackgroundWater from './components/BackgroundWater';
import BackgroundPlain from './components/BackgroundPlain';
import BreathingArea from './components/BreathingArea';
import MenuPanel from './components/MenuPanel';
import UserContext from './components/Context';

// const  = createContext();
function App() {
  const [sessionTime, setSessionTime] = useState(300);
  const [currentTime, setCurrentTime] = useState(sessionTime);

  const [activeBackground, setActiveBackground] = useState('water');

  const [upTime, setUpTime] = useState(4);
  const [holdTime, setHoldTime] = useState(0);
  const [downTime, setDownTime] = useState(6);
  const [waitTime, setWaitTime] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [language, setLanguage] = useState('EN');

  const backgroundComponent = activeBackground=='water' ? <BackgroundWater/> : <BackgroundPlain/>;
  return (
    <UserContext.Provider value={{ 
      sessionTime: [sessionTime, setSessionTime], 
      upTime: [upTime, setUpTime],
      holdTime: [holdTime, setHoldTime],
      downTime: [downTime, setDownTime],
      waitTime: [waitTime, setWaitTime],
      menuVisible: [menuVisible, setMenuVisible],
      isActive: [isActive, setIsActive],
      language: [language, setLanguage],
      currentTime: [currentTime, setCurrentTime],
      activeBackground: [activeBackground, setActiveBackground],
    }}>
      <div className='app'>
        {backgroundComponent}
        <MenuPanel/>
        <BreathingArea/>
      </div>
    </UserContext.Provider>
  );
}

export default App;
