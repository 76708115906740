import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import UserContext from './Context';
import "./BreathingArea.css";

import playBtn from '../images/play.png';
import pauseBtn from '../images/pause.png';
import bubbleImg from '../images/bubble.png';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const TimerContext = createContext();
export default function BreathingArea(){
	const { height, width } = useWindowDimensions();
	const [sessionTime, setSessionTime] = useContext(UserContext).sessionTime;
	const [upTime, setUpTime] = useContext(UserContext).upTime;
	const [holdTime, setHoldTime] = useContext(UserContext).holdTime;
	const [downTime, setDownTime] = useContext(UserContext).downTime;
	const [waitTime, setWaitTime] = useContext(UserContext).waitTime;

	const [isActive, setIsActive] = useContext(UserContext).isActive;
	let [currentTime, setCurrentTime] = useContext(UserContext).currentTime;

	const roundTime = upTime+downTime;

	const language = useContext(UserContext).language[0];
	let content = {
		EN: {
			up_text: 'Breathe in',
			hold_text: 'Hold',
			down_text: 'Breathe out',
			wait_text: 'Hold',
		},
		FR: {
			up_text: 'Inspirer',
			hold_text: 'Retenez votre souffle',
			down_text: 'Expirer',
			wait_text: 'Retenez votre souffle',
		}
	};
	content = content[language];
	
	const stateTransitions = {
		up: { transition: 'hold', timer: upTime, text: content['up_text'] },
		hold: { transition: 'down', timer: holdTime, text: content['hold_text'] },
		down: { transition: 'wait', timer: downTime, text: content['down_text'] },
		wait: { transition: 'up', timer: waitTime, text: content['wait_text'] },
	};
	const [currentState, setCurrentState] = useState('up');
	const [stateTimer, setStateTimer] = useState(0);
	const [stateText, setStateText] = useState(stateTransitions['up']['text'])
	function TransitionState() {
		var nextState = stateTransitions[currentState]['transition'];
		if (!nextState) throw new Error(`invalid transition from: ${currentState}`);
		setCurrentState(nextState)
		setStateText(stateTransitions[nextState]['text']);
	}
	function UpdateState(){
		while(stateTimer >= stateTransitions[currentState]['timer']){
			TransitionState();
			setStateTimer(0);
			return;
		}
		setStateTimer(stateTimer => stateTimer += 0.005);
		
	}
	useInterval(() => {
		if(currentTime <= 0) {
			setCurrentTime(0);
			setIsActive(false);
		}
		else{
			UpdateState();
			setCurrentTime(currentTime - 0.005);
		}
	}, isActive? 5 : null);
	
	useEffect(() => {
		setStateText(stateTransitions[currentState]['text']);
	}, [language]);


	function toggle() {
		setIsActive(!isActive);
	}

	function reset() {
		setCurrentTime(sessionTime);
		setIsActive(false);
	}

	return (
		<TimerContext.Provider value={{ 
	      isActive: [isActive, setIsActive], 
	      currentTime: [currentTime, setCurrentTime],
	      toggle: toggle,
	      reset: reset,
	      stateTimer: stateTimer,
	      currentState: currentState,
	      stateText: stateText,
	    }}>
			<div className="breathing-area">
				<BreathingBubble/>
				<BreathingText/>
				<BreathingTimer/>
				<BreathingTogglePlay/>
			</div>
		</TimerContext.Provider>
	);
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}




function BreathingBubble(){

	const timerContext = useContext(TimerContext);
	const [isActive, setIsActive] = timerContext.isActive;
	const userContext = useContext(UserContext);
	const [upTime, setUpTime] = userContext.upTime;
	const [downTime, setDownTime] = userContext.downTime;



	const timer = timerContext.stateTimer;
	const barHeight = 500; 
	const bubbleHeight = 80;
	const frames = 5;

	const upHeight = (barHeight-bubbleHeight)/upTime;
	const upHeightPerInterval = upHeight*frames/1000 ;

	const downHeight = (barHeight-bubbleHeight)/downTime;
	const downHeightPerInterval = downHeight*frames/1000 ;

	let height = 0;
	const state = timerContext.currentState;
	if(state == 'up'){
		height = upHeightPerInterval*(timer)*1000/frames;
	}
	else if(state == 'down'){
		height = barHeight - bubbleHeight - downHeightPerInterval*(timer)*1000/frames;
	}
	else if(state == 'hold'){
		height = barHeight - bubbleHeight;
	}
	let bubbleHeightStyle = {
		bottom: `${height}px`,
    };
	return(
		<div className="breathing-bubble-area">
			<div className="breathing-bubble" style={bubbleHeightStyle}>
			<img src={bubbleImg} className=''  alt=''/>
			</div>
		</div>
	);
}

function BreathingText() {
	const context = useContext(TimerContext);
	const language = useContext(UserContext).language[0];
	const [isActive, setIsActive] = context.isActive;
	let content = {
		EN: {
			inactive_text: 'Ready to start',
		},
		FR: {
			inactive_text: 'Prêt à commencer',
		}
	};
	content = content[language];
	console.log(context);
	const text = isActive ? context.stateText : content['inactive_text'];

	// const
	const nodeRef = React.useRef(null);
	return (
		<SwitchTransition mode="out-in">
			<CSSTransition
			 nodeRef={nodeRef}
	          classNames="fade"
	          timeout={400}
	          key={text}
	        >
				<div ref={nodeRef} className="breathing-text">
					{text}
				</div>
			</CSSTransition>
      	</SwitchTransition>
	);
}

function BreathingTimer() {
	const [currentTime, setCurrentTime] = useContext(TimerContext).currentTime;
	const currentTimeObj = secondsToTime(currentTime);
	return (
		<div className="breathing-timer">
			{currentTimeObj.m} : {currentTimeObj.s}
		</div>
	);
}

function BreathingTogglePlay(){
	const context = useContext(TimerContext);
	const [isActive, setIsActive] = context.isActive;
	return(

		<div className={`breathing-toggle breathing-toggle--${isActive ? 'active' : 'inactive'}`} onClick={context.toggle}>
			<img src={isActive ? pauseBtn : playBtn } className=''  alt=''/>
		</div>

	);

}

function secondsToTime(s){
	let divisor_for_minutes = Math.floor(s % (60 * 60));
	let minutes = Math.floor(divisor_for_minutes / 60);
	let divisor_for_seconds = divisor_for_minutes % 60;
	let seconds = Math.ceil(divisor_for_seconds);
	seconds = seconds.toLocaleString('en-US', {
	    minimumIntegerDigits: 2,
	    useGrouping: false
	});
	let obj = {
      "m": minutes,
      "s": seconds
    };
    return obj;
}